import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserInfo } from 'src/app/layouts/main/states/main.state';
import { environment } from '../../../environments/environment';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public apiUrl = environment.API_URL;
  private readonly url=environment.API_URL3
  private readonly apiUrlLive = environment.API_URL

  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: StorageService,
  ) {}

  login(body: any): Observable<any> {
    return this.http.post(this.apiUrlLive + '/api/authenticate', body);
  }

  logout(): void {
    this.storageService.clearStorage();
    this.router.navigate(['/auth/login']);
  }
  getInfor(): Observable<any> {
    return  this.http.get<any>(this.url+'/gateway/member/view-own')
  }

  private userInfoInit: UserInfo = {
    id: "",
    fullName: "",
    username: ""
  }
  private userInfo: BehaviorSubject<UserInfo> = new BehaviorSubject<UserInfo>(this.userInfoInit);
  public userInfo$: Observable<UserInfo> = this.userInfo.asObservable();

  setUserInfo(data: UserInfo) {
    this.userInfo.next(data);
  }


}
